import React, {useState} from "react";
import ReactMapGL, {FlyToInterpolator, Popup, ViewportProps} from 'react-map-gl';
import './map.css'

// styles
import {Infrastructure} from "../../context/infrastructures";
import Pins from "./pins";
import InfrastructureInfo from './InfrastructureInfo'
import InfrastructureListPanel from "./InfrastructureListPanel";

interface InfrastructureMapProps {
    infrastructures: Infrastructure[]
}


export default function InfrastructureMap({infrastructures}: InfrastructureMapProps) {
    const [viewport, setViewport] = useState<ViewportProps>({
        latitude: 52.2,
        longitude: 5,
        zoom: 6
    });

    const [selectedInfrastructure, setSelectedInfrastructure] = useState<Infrastructure | undefined>(undefined);

    const moveToLatLng = (lat: number, lng: number) => {
        setViewport({
            ...viewport,
            longitude: lng,
            latitude: lat,
            zoom: 14,
            transitionInterpolator: new FlyToInterpolator(),
            transitionDuration: 1000
        });
    }

    return (
        <ReactMapGL
            {...viewport}
            width="100%"
            height="90%"
            mapboxApiAccessToken='pk.eyJ1IjoiYWZzaG1uZXh0IiwiYSI6ImNrdzR5bm9kcDI3amgyeG5vOTVvbWF1cXQifQ.VnuYLGwqtn5nzO7QQGT6tA'
            mapStyle="mapbox://styles/mapbox/streets-v11"
            onViewportChange={(nextViewport: any) => setViewport(nextViewport)}
        >
            <Pins infrastructures={infrastructures}
                  onClick={(infrastructure) => setSelectedInfrastructure(infrastructure)}/>
            {selectedInfrastructure && <Popup
                tipSize={5}
                anchor="top"
                longitude={selectedInfrastructure.lng}
                latitude={selectedInfrastructure.lat}
                closeOnClick={false}
                onClose={() => setSelectedInfrastructure(undefined)}
            >
                <InfrastructureInfo infrastructure={selectedInfrastructure}/>
            </Popup>}
            <InfrastructureListPanel infrastructures={infrastructures} moveToLatLng={moveToLatLng}/>
        </ReactMapGL>


    );
}