import React from 'react'
import {API_BASE_URL} from '../config'
import {useAuthentication} from "./authentication";
import traverson from 'traverson';
import {Infrastructure} from "./infrastructures";
import axios from "axios";


export interface UniqLocation {
    id: string;
    name: string;
    type: string | undefined;
    created_at: Date;
    _links: {
        self: {
            href: string
        },
        infrastructure: {
            name: string
            href: string
        }
    }
    infrastructure?: Infrastructure
}

interface CollectionApiResponse {
    _embedded: {
        uniq_locations: [UniqLocation]
    }

}

interface HistogramResponse {
    histogram: {
        [date: string]: number
    }
}

interface APIContext {
    getLocationsByInfrastructureId: (id: string) => Promise<UniqLocation[] | undefined>,
    getLocationById: (id: string) => Promise<UniqLocation | undefined>,
    histogram: (id: string) => Promise<{ [date: string]: number } | undefined>,
}

const UniqLocationContext = React.createContext<APIContext>({
    getLocationsByInfrastructureId: () => {
        return new Promise((resolve, reject) => resolve(undefined))
    },
    getLocationById: () => {
        return new Promise((resolve, reject) => resolve(undefined))
    },
    histogram: () => {
        return new Promise((resolve, reject) => resolve(undefined))
    }
})

const UniqLocationProvider = (props: any) => {
    const {tokens: {access_token}, logout} = useAuthentication()

    const getLocationsByInfrastructureId = async (infrastructure_id: string) => {
        const url = `${API_BASE_URL}/uniq_locations/infrastructure/${infrastructure_id}`

        return new Promise<UniqLocation[] | undefined>((resolve, reject) => {
            traverson.from(url).withRequestOptions({
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).json().getResource((error: any, result: CollectionApiResponse, traversal) => {

                if (error) {
                    logout()
                    return
                }

                resolve(result._embedded.uniq_locations.map((loc: UniqLocation) => {
                    loc.type = "UNIQ"
                    return loc
                }));

            })
        })
    }

    const histogram = (id: string) => {
        const url = `${API_BASE_URL}/raw_uniq_measurements/histogram/${id}`

        return axios.get(url, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }).then(response => {
            const favorite: HistogramResponse = response.data
            return favorite.histogram;
        }).catch(_ => {
            logout();
        })
    }


    const getLocationById = (uniq_location_id: string) => {
        const url = `${API_BASE_URL}/uniq_locations/id/${uniq_location_id}`

        return new Promise<UniqLocation | undefined>((resolve, reject) => {

            traverson.from(url).withRequestOptions({
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).json().getResource((error: any, result, traversal) => {
                if (error) logout();
                const location: UniqLocation = result;
                if (!traversal) resolve(location)

                traversal?.continue().follow("$._links.infrastructure.href").getResource((error: any, infrastructure, traversal) => {
                    location.infrastructure = infrastructure;
                    resolve(location)
                })

            })
        });
    }


    return (<UniqLocationContext.Provider
        value={{
            getLocationById,
            histogram,
            getLocationsByInfrastructureId
        }} {...props}/>)
}
const useUniqLocations = () => React.useContext(UniqLocationContext)
export {UniqLocationProvider, useUniqLocations}
