import React from "react";
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";

import Layout from "./components/Layout/Layout";
import Login from "./pages/login/Login"
import {useAuthentication} from "./context/authentication";

interface PrivateRouteProps {
    component?: any,
    path: any
    exact: boolean,
    children?: any;
}

export function App() {
    const {isAuthenticated} = useAuthentication();

    const PrivateRoute = ({component, ...rest}: PrivateRouteProps) => {
        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated() ? (
                        React.createElement(component, props)
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {
                                    from: props.location,
                                },
                            }}
                        />
                    )
                }
            />
        );
    }

    return (
        <Router>
            <Switch>
                <Route path="/login" component={Login}/>
                <PrivateRoute exact={false} path="/" component={Layout}/>
            </Switch>
        </Router>
    );
}
