import * as React from 'react';
import {Infrastructure} from "../../context/infrastructures";
import {Link} from 'react-router-dom'

interface InfoProps {
    infrastructure: Infrastructure
}

function InfrastructureInfo({infrastructure}: InfoProps) {
    return (
        <div style={{width: "300px", height: "200px"}}>
            < div>
                {infrastructure.name}
            </div>
            <Link to={`/infrastructures/${infrastructure.id}`}>
                <img src={`/images/${infrastructure.id}/medium.jpg`}
                     style={{backgroundColor: 'lightgray', borderRadius: '2px', display: 'block'}} width={200}
                     height={100}/>
            </Link>

        </div>
    );
}

export default React.memo(InfrastructureInfo);