import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
    titleContainer: {
        marginBottom: theme.spacing(1),
    },
    tabs: {
        marginLeft: theme.spacing(4)
    },
    typo: {
        paddingTop: theme.spacing(1),
        color: theme.palette.text.primary,
    },
    centeredHint: {
        color: theme.palette.text.hint,
    },
    link: {
        color: theme.palette.text.primary
    },
    valueHeader: {
        fontSize: theme.typography.body1.fontSize,
        marginRight: '2rem'
    },
    downloadButton: {
        marginRight: '2rem',
        cursor: 'pointer'
    },
    actionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2)
        }
    },
    firstSection: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            height: 'auto'
        },
    },
    secondSection: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            height: 'auto'
        },
    },
    thirdSection: {
        paddingBottom: theme.spacing(2),
        height: '300px',
        [theme.breakpoints.down("sm")]: {
            height: 'auto'
        },
    },
    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    sectionHeader: {
        color: theme.palette.text.primary,
    },
    title: {
        marginTop: '1rem',
        marginRight: "2rem"
    },
    actionRow: {
        marginTop: '2rem',
        display: 'flex',
        flex: 1,
        justifyContent: 'end',
        width: '100%',
    },
    fullHeightBody: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "space-between",
    },
    card: {
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
    },
    titleLoader: {},
}));
