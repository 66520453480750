import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
    card: {
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
    },
    title: {
        color: theme.palette.text.hint,
    },
    fullHeightBody: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "space-between",
    },
    tableWidget: {
        overflowX: "auto",
    },
}));
