import {makeStyles} from "@material-ui/styles";
import {SHMNextTheme} from "../../themes";
import {Theme} from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
    pageTitleContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(2),
    },
    typo: {
        color: theme.palette.text.primary,
    },
    button: {
        boxShadow: theme.customShadows.widget,
        textTransform: "none",
        "&:active": {
            boxShadow: theme.customShadows.widgetWide,
        },
    },
    actionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}));
