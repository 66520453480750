import React, {useEffect, useState} from "react";
import {
    Grid,
    CircularProgress,
    Typography,
    Button,
    TextField,
    Fade,
} from "@material-ui/core";
import {RouteComponentProps, useHistory, withRouter} from "react-router-dom";

import useStyles from "./styles";
import logo from "../../images/shmnext_logo.png";

import {useAuthentication} from "../../context/authentication";

interface LoginPageProps extends RouteComponentProps<any> {
}

function Login(props: LoginPageProps) {

    const {isAuthenticated, login} = useAuthentication();
    const history = useHistory();
    useEffect(() => {
        if (isAuthenticated()) {
            props.history.push('/')
        }
    }, [])
    const classes = useStyles();


    // local
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loginValue, setLoginValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");

    return (
        <Grid container className={classes.container}>
            <div className={classes.logotypeContainer}>
                <img src={logo} alt="logo" className={classes.logotypeImage}/>
            </div>
            <div className={classes.formContainer}>
                <div className={classes.form}>
                    <Typography variant="h1" className={classes.greeting}>
                        Good Morning, User
                    </Typography>
                    <Fade in={error !== undefined}>
                        <Typography color="secondary" className={classes.errorMessage}>
                            {error}
                        </Typography>
                    </Fade>
                    <TextField
                        id="email"
                        InputProps={{
                            classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                            },
                        }}
                        value={loginValue}
                        onChange={e => setLoginValue(e.target.value)}
                        margin="normal"
                        placeholder="Email Address"
                        type="email"
                        fullWidth
                    />
                    <TextField
                        id="password"
                        InputProps={{
                            classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                            },
                        }}
                        value={passwordValue}
                        onChange={e => setPasswordValue(e.target.value)}
                        margin="normal"
                        placeholder="Password"
                        type="password"
                        fullWidth
                    />
                    <div className={classes.formButtons}>
                        {isLoading ? (
                            <CircularProgress size={26} className={classes.loginLoader}/>
                        ) : (
                            <Button
                                disabled={
                                    loginValue.length === 0 || passwordValue.length === 0
                                }
                                onClick={() => {
                                    setIsLoading(true)
                                    login(
                                        loginValue,
                                        passwordValue
                                    ).then(({success, error}: { success: boolean, error?: string }) => {
                                            setIsLoading(false)
                                            if (!success) {
                                                setError(error)
                                            } else {
                                                history.push("/")
                                            }
                                        }
                                    )
                                }}
                                variant="contained"
                                color="primary"
                                size="large"
                            >
                                Login
                            </Button>
                        )}
                        <Button
                            color="primary"
                            size="large"
                            className={classes.forgetButton}
                        >
                            Forget Password
                        </Button>
                    </div>
                </div>
            </div>
        </Grid>
    );
}

export default withRouter(Login);
