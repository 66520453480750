import React, {useEffect, useState} from "react";
import {
    Button, Card, CardActions, CardContent, CardMedia,
    Grid, Typography,
} from "@material-ui/core";

import {Link} from 'react-router-dom'
import PageTitle from "../../components/PageTitle/PageTitle";
import {Infrastructure, useInfrastructures} from "../../context/infrastructures";
import useStyles from "./styles";

interface DashboardPageProps {

}

export default function Dashboard(props: DashboardPageProps) {

    const {getPinnedInfrastructures} = useInfrastructures()
    const classes = useStyles();

    const [pinnedInfrastructures, setPinnedInfrastructures] = useState<Infrastructure[]>([]);

    const update = () => {
        getPinnedInfrastructures().then(infrastructures => {
            setPinnedInfrastructures(infrastructures)
        });
    }

    useEffect(() => {
        update();
    }, [])

    return (
        <>
            <PageTitle title="Dashboard"/>
            <Grid container spacing={3}>
                {pinnedInfrastructures.length !== 0 && pinnedInfrastructures.map((pin: Infrastructure) => (
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Card>
                            <CardMedia
                                component="img"
                                width="100%"
                                image={`/images/${pin.id}/medium.jpg`}
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    <Link to={`/infrastructures/${pin.id}`}>
                                        {pin.name}
                                    </Link>
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Latitude: {pin.lat} Longitude: {pin.lng}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
                {pinnedInfrastructures.length === 0 && (
                    <>
                        Pin infrastructures to have them appear here!
                    </>
                )}
            </Grid>
        </>
    )
        ;
}
