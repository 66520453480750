import * as React from 'react';
import './map.css'
import MUIDataTable from "mui-datatables";
import {Infrastructure} from "../../context/infrastructures";

interface InfrastructureListPanelProps {
    infrastructures: Infrastructure[],
    moveToLatLng: (lat: number, lng: number) => void;
}

const columns = [
    {
        name: "name",
        label: "Name",
        options: {
            filter: false
        }
    },
    {
        name: "id",
        label: "Id",
        options: {
            filter: false
        }
    }
];

function InfrastructureListPanel({infrastructures, moveToLatLng}: InfrastructureListPanelProps) {
    return (
        <div className="control-panel">
            <MUIDataTable
                title='Infrastructures'
                data={infrastructures}
                columns={columns}
                options={{
                    download: false,
                    selectableRows: 'none',
                    onRowClick: (data, rowMeta: { dataIndex: number; rowIndex: number }) => {
                        moveToLatLng(infrastructures[rowMeta.rowIndex].lat, infrastructures[rowMeta.rowIndex].lng);
                    }
                }}
            />
        </div>
    );
}

export default React.memo(InfrastructureListPanel);