import React from "react";

interface ILayoutState extends IReducerState {
    dispatch: React.Dispatch<LayoutActions>
}

const LayoutContext = React.createContext({
    isSidebarOpened: true, dispatch: () => null
} as ILayoutState);



type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
        ? {
            type: Key;
        }
        : {
            type: Key;
            payload: M[Key];
        }
};

export enum Types {
    Toggle = 'TOGGLE_SIDEBAR',

}

type SidebarPayload = {
    [Types.Toggle] : {};
}

export type LayoutActions = ActionMap<SidebarPayload>[keyof ActionMap<SidebarPayload>];

interface IReducerState {
    isSidebarOpened: boolean;

}

function layoutReducer(state: IReducerState, action: LayoutActions) {
    switch (action.type) {
        case Types.Toggle:
            return {...state, isSidebarOpened: !state.isSidebarOpened};
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

const LayoutProvider: React.FC = ({children}) => {
    const [state, dispatch] = React.useReducer(layoutReducer, {
        isSidebarOpened: true
    });
    const value = {...state, dispatch}
    return (
        <LayoutContext.Provider value={value}>
            {children}
        </LayoutContext.Provider>
    );
}

function useSidebar() {
    var context = React.useContext(LayoutContext);
    if (context === undefined) {
        throw new Error("useLayoutState must be used within a LayoutProvider");
    }
    return context;
}

export {LayoutProvider, useSidebar, toggleSidebar};

// ###########################################################
function toggleSidebar(dispatch: React.Dispatch<any>) {
    dispatch({
        type: "TOGGLE_SIDEBAR",
    });
}
