import React from "react";
import {
    Grid,
} from "@material-ui/core";

import PageTitle from "../../components/PageTitle/PageTitle";
import useStyles from './styles'

interface ReportsPageProps {

}

export default function ReportsPage(props: ReportsPageProps) {

    return (
        <>
            <PageTitle title="Reports"/>
            <Grid container spacing={4}>
                <Grid item lg={3} md={4} sm={6} xs={12}>

                </Grid>
            </Grid>
        </>
    );
}
