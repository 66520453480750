import React, {useState} from 'react'
import {API_BASE_URL} from "../config";
import axios from 'axios'

interface UserTokens {
    access_token?: string;
    email?: string;
}

interface UserContext {
    tokens: UserTokens,
    isAuthenticated: Function,
    login: Function,
    logout: Function,
}

const AuthContext = React.createContext<UserContext>({
    tokens: {},
    login: () => null,
    logout: () => null,
    isAuthenticated: () => null
})

const AuthenticationProvider = (props: any) => {

    const existingTokens = JSON.parse(localStorage.getItem("tokens") || "{}");

    const [tokens, setAccessTokens] = useState<UserTokens>(existingTokens)

    const isAuthenticated = () => {
        return tokens.access_token !== undefined;
    }

    const setTokens = (tokens: UserTokens) => {
        localStorage.setItem("tokens", JSON.stringify(tokens));
        setAccessTokens(tokens)
    }

    const login = (email: string, password: string) => {
        return axios.post(`${API_BASE_URL}/auth/login`, {
            username: email.toLocaleLowerCase(), password
        }).then((response) => {
            if (response.status === 200) {
                setTokens(response.data);
                return {success: true}
            }
            return {success: false, message: "Invalid username or password"}
        }).catch(reason => {
            return {success: false, message: "Invalid username or password"}
        })
    }

    const logout = () => {
        setTokens({access_token: undefined, email: undefined})
    }

    return (
        <AuthContext.Provider value={{tokens, isAuthenticated, login, logout}} {...props} />
    )
}
const useAuthentication = () => React.useContext(AuthContext)
export {AuthenticationProvider, useAuthentication}

