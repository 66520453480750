import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
    fullHeight: {
        display: 'flex',
        minHeight: "300px",
        flexGrow: 1
    },
    halveHeightBody: {
        display: "flex",
        height: "75%",
    },
    card: {
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
    },
    titleLoader: {},
    title: {
        marginTop: '1rem',
        marginRight: "2rem"
    },
    divider: {
        marginTop: '2rem',
        marginBottom: '2rem'
    },
    tableHeight: {
        height: '100%'
    },
    actionRow: {
        marginTop: '2rem',
        display: 'flex',
        flex: 1,
        justifyContent: 'end',
        width: '100%'
    },
    image: {

    }
}));
