import React, {useEffect, useState} from "react";
import {Infrastructure, useInfrastructures} from "../../context/infrastructures";
import InfrastructureMap from "../../components/InfrastructureMap/Map";

interface InfrastructuresPageProps {

}

export default function InfrastructuresPage(props: InfrastructuresPageProps) {
    const {getAccessibleInfrastructures} = useInfrastructures();
    const [infrastructures, setInfrastructures] = useState<Infrastructure[]>([])

    useEffect(() => {
            getAccessibleInfrastructures().then(((infrastructures: Infrastructure[]) => {
                    setInfrastructures(infrastructures)
                })
            )
        }, []
    )

    return (
        <InfrastructureMap infrastructures={infrastructures}/>
    );
}
