import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Button, CircularProgress, Divider, Fab,
    Grid, Typography,
} from "@material-ui/core";
import {Link} from 'react-router-dom'
import useStyles from './styles'
import {UniqLocation, useUniqLocations} from "../../context/uniq_locations";
import {CloudDownloadOutlined} from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import {
    AkzoNobel,
    RawUniqMeasurement,
    SimplifiedRawUniqMeasurement,
    useRawUniqMeasurements
} from "../../context/raw_uniq_measurements";
import {Dataset, useDatasets} from "../../context/datasets";
import {AddIcon} from "@material-ui/data-grid";
import WaveForm from "../../components/Waveform";
import Widget from "../../components/Widget";
import {ParsedUniq, useUniqs} from "../../context/uniqs";
import HistogramPlot from "../../components/HistogramPlot";
import SelectToolbar from "../../components/SelectToolbar";

interface UniqLocationDetailPageProps {

    match: {
        params: {
            uniq_location_id: string
        }
    }

}

const waveformColumns = [
    {
        name: "id",
        label: "Id",
    },
    {
        name: "measured_at",
        label: 'Measured at'
    },
];

const datasetsColumns = [
    {
        name: "id",
        label: "Id",
    },
    {
        name: "name",
        label: 'Name',
        options: {
            sort: false,
            filter: false
        }
    },
];

interface MultiDownloadButtonProps {
    selectedRows: { data: { index: number; dataIndex: number; }[]; lookup: { [key: number]: boolean; }; }
    data: SimplifiedRawUniqMeasurement[]
}

const MultiDownloadButton = (props: MultiDownloadButtonProps) => {
    const classes = useStyles();
    const {downloadIdsInList} = useRawUniqMeasurements();
    return <><CloudDownloadOutlined className={classes.downloadButton}
                                    onClick={() => downloadIdsInList(props.selectedRows.data.map(r => parseInt(props.data[r.dataIndex].id)))}/></>
}

function range(start: number, stop: number, step: number) {
    if (typeof stop == 'undefined') {
        // one param defined
        stop = start;
        start = 0;
    }

    if (typeof step == 'undefined') {
        step = 1;
    }

    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
        return [];
    }

    let result = [];
    for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
        result.push(i);
    }

    return result;
}

export default function UniqLocationDetailPage(props: UniqLocationDetailPageProps) {
    const {getLocationById, histogram} = useUniqLocations();
    const {getUniqByLocationId} = useUniqs();
    const {getDatasetsByUniqLocation} = useDatasets();
    const {getSimplifiedMeasurementsByLocationId, getFullMeasurementById, getAkzoNobelById} = useRawUniqMeasurements();

    const [location, setLocation] = useState<UniqLocation | undefined>(undefined)
    const [attachedUniq, setAttachedUniq] = useState<ParsedUniq | undefined>(undefined)
    const [datasets, setDatasets] = useState<Dataset[] | undefined>(undefined)
    const [measurements, setMeasurements] = useState<SimplifiedRawUniqMeasurement[] | undefined>(undefined);
    const [histogramV, setHistogram] = useState<{ [date: string]: number } | undefined>(undefined);

    const [loadingMeasurements, setLoadingMeasurements] = useState(false);

    const [selectedRows, setSelectedRows] = useState<any>([])
    const [rowsInPage, setRowsInPage] = useState<[number, number]>([0, 10]);


    const classes = useStyles();

    const refresh = () => {
        setLoadingMeasurements(true)

        getSimplifiedMeasurementsByLocationId(props.match.params.uniq_location_id).then(((measurements: SimplifiedRawUniqMeasurement[] | undefined) => {
                setMeasurements(measurements);
                setLoadingMeasurements(false);
            })
        )
        getLocationById(props.match.params.uniq_location_id).then(((location: UniqLocation | undefined) => {
                setLocation(location)
            })
        )
        getDatasetsByUniqLocation(props.match.params.uniq_location_id).then(((dataset: Dataset[] | undefined) => {
                setDatasets(dataset)
            })
        )
        getUniqByLocationId(props.match.params.uniq_location_id).then(((uniq: ParsedUniq | undefined) => {
                setAttachedUniq(uniq)
            })
        )
        histogram(props.match.params.uniq_location_id).then((histogramV: { [date: string]: number } | undefined) => {
            setHistogram(histogramV);
        });
    }

    const getSelectedDataPoints = (id: string) => {
        getFullMeasurementById(id).then(measurement => {
            setSelectedMeasurement(measurement);
        })
        if (!datasets) return;
        getAkzoNobelById(datasets[0].id, id).then(akzonobel => {
            setSelectedAkzoNobel(akzonobel)
        })
    }

    useEffect(() => {
        refresh();
    }, []);

    const [selectedMeasurement, setSelectedMeasurement] = useState<RawUniqMeasurement | undefined>(undefined);
    const [selectedDataset, setSelectedDataset] = useState<Dataset | undefined>(undefined);
    const [selectedAkzoNobel, setSelectedAkzoNobel] = useState<AkzoNobel | undefined>(undefined);

    let linkRef = useRef<HTMLAnchorElement>(null)

    useEffect(() => {

        if (selectedMeasurement) {
            let csvContent = "data:text/csv;charset=utf-8,";
            let index = 0;
            selectedMeasurement.ch1.forEach(function (rowArray) {
                let row = selectedMeasurement.ch1[index] + " " +
                    selectedMeasurement.ch2[index] + " " +
                    selectedMeasurement.ch3[index] + " " +
                    selectedMeasurement.ch4[index]
                index++;
                csvContent += row + "\r\n";
            });


            const encodedUri = encodeURI(csvContent);
            if (linkRef.current) {
                let link = linkRef.current
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", selectedMeasurement.measured_at + ".txt");
                link.setAttribute("href", encodedUri);
            }
        }

    }, [selectedMeasurement])

    return (
        <>
            {!location && <CircularProgress size={26} className={classes.titleLoader}/>
            }
            {location && <>

                <Grid container className={classes.titleContainer}>
                    <Grid item>
                        <Typography className={classes.typo} variant="h2">
                            {location.name}
                        </Typography>
                    </Grid>
                    <Grid style={{flexGrow: 1}}/>
                    <Grid item className={classes.actionContainer}>
                        <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() => refresh()}
                        >
                            Refresh
                        </Button>
                    </Grid>
                </Grid>
                <Divider className={classes.divider}/>
                <section className={classes.firstSection}>
                    <Typography variant="h3" className={classes.sectionHeader}>Waveforms</Typography>
                    <Grid container spacing={4}>

                        <Grid container item lg={7} md={4} sm={6} xs={12}>
                            {!selectedMeasurement && (
                                <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                                    <Typography variant="h6" className={classes.centeredHint}>Select a raw measurement
                                        to
                                        inspect it.</Typography>
                                </div>
                            )}
                            {selectedMeasurement && (
                                <>
                                    <Grid container spacing={4} style={{marginTop: '2rem'}}>
                                        <Grid item lg={6} md={4} sm={6} xs={12}>
                                            <WaveForm data={selectedMeasurement.ch1} height={200} width="100%"
                                                      title={"Channel 1"}/>
                                        </Grid>
                                        <Grid item lg={6} md={4} sm={6} xs={12}>
                                            <WaveForm data={selectedMeasurement.ch2} height={200} width="100%"
                                                      title={"Channel 2"}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4} style={{marginTop: '2rem'}}>
                                        <Grid item lg={6} md={4} sm={6} xs={12}>
                                            <WaveForm data={selectedMeasurement.ch3} height={200} width="100%"
                                                      title={"Channel 3"}/>
                                        </Grid>
                                        <Grid item lg={6} md={4} sm={6} xs={12}>
                                            <WaveForm data={selectedMeasurement.ch4} height={200} width="100%"
                                                      title={"Channel 4"}/>
                                        </Grid>
                                    </Grid>
                                    <div>
                                        {selectedAkzoNobel && (
                                            <div>
                                                Output 1: {selectedAkzoNobel.output_1} <br/>
                                                Output 2: {selectedAkzoNobel.output_2} <br/>
                                                Output 3: {selectedAkzoNobel.output_3} <br/>
                                                Output 4: {selectedAkzoNobel.output_4} <br/>
                                                Output 5: {selectedAkzoNobel.output_5} <br/>

                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <a id="download-button" ref={linkRef}></a>
                                        <button onClick={() => linkRef.current && linkRef.current.click()}>Download
                                        </button>

                                    </div>
                                </>)}
                        </Grid>

                        <Grid item lg={5} md={4} sm={6} xs={12}>
                            <MUIDataTable columns={waveformColumns} data={measurements || []}
                                          title={"Raw measurements"} options={{
                                download: false,
                                print: false,
                                tableBodyHeight: '625px',
                                rowsSelected: selectedRows,
                                onTableChange: (action, tableState) => {
                                    if (action !== 'changePage' && action !== 'changeRowsPerPage') return;
                                    setRowsInPage([
                                        (tableState.page) * tableState.rowsPerPage,
                                        Math.min(tableState.data.length, tableState.rowsPerPage * (tableState.page + 1))
                                    ])
                                },
                                customToolbar: () => (<SelectToolbar handleClick={() => {
                                    setSelectedRows([...selectedRows, ...range(rowsInPage[0], rowsInPage[1], 1)])
                                }}/>),
                                onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
                                    setSelectedRows(rowsSelected);
                                },
                                customToolbarSelect: selectedRows => (
                                    <MultiDownloadButton selectedRows={selectedRows}
                                                         data={measurements || []}/>
                                ),
                                selectableRows: 'multiple',
                                onRowClick: (data, rowMeta: { dataIndex: number; rowIndex: number }) => {
                                    if (!measurements) return
                                    getSelectedDataPoints(measurements[rowMeta.dataIndex].id)
                                }

                            }}/>
                        </Grid>
                    </Grid>
                </section>
                <Divider className={classes.divider}/>
                <section className={classes.secondSection}>
                    <Typography variant="h3" className={classes.sectionHeader}>Datasets</Typography>
                    <Grid container spacing={4}>
                        <Grid item lg={7} md={4} sm={6} xs={12}>
                            {!selectedDataset &&
                            <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                                <Typography variant="h6" className={classes.centeredHint}>Select a dataset
                                    to see more information.</Typography>
                            </div>}
                            {selectedDataset && (
                                <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                                    <div style={{marginTop: '2rem'}}>
                                        <span className={classes.valueHeader}>Name:</span> {selectedDataset.name}<br/>
                                    </div>
                                    <br/>
                                    <Box width={"50%"} height={"200px"} style={{
                                        backgroundColor: 'lightgrey',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: '2rem'
                                    }}>
                                        Graph to display from and to dates
                                    </Box>
                                    <Link to={`/datasets/${selectedDataset.id}`}
                                          style={{marginTop: '2rem'}}>
                                        <Typography variant="h3" className={classes.link}>See processed
                                            measurements</Typography>
                                    </Link>
                                </div>
                            )}
                        </Grid>
                        <Grid item lg={5} md={4} sm={6} xs={12}>
                            {loadingMeasurements && <CircularProgress/>}
                            {!loadingMeasurements && <>
                                <MUIDataTable columns={datasetsColumns} data={datasets || []}
                                              title={"Datasets"} options={{
                                    download: false,
                                    print: false,
                                    tableBodyHeight: '325px',
                                    customToolbarSelect: (rows) => (<></>),
                                    selectableRows: 'none',
                                    onRowClick: (data, rowMeta: { dataIndex: number; rowIndex: number }) => {
                                        if (!datasets) return;
                                        setSelectedDataset(datasets[rowMeta.dataIndex]);
                                    }
                                }}
                                />
                                <div className={classes.actionRow}>
                                    <Fab color="primary" disabled aria-label="add">
                                        <AddIcon/>
                                    </Fab>
                                </div>
                            </>
                            }
                        </Grid>
                    </Grid>
                </section>
                <Divider className={classes.divider}/>
                <section className={classes.secondSection}>
                    <Typography variant="h3" className={classes.sectionHeader}>Histogram of events per date</Typography>
                    <Grid container spacing={4} style={{paddingBottom: '1rem'}}>
                        <Grid item lg={12}>
                            <HistogramPlot data={histogramV} location={location}/>
                        </Grid>
                    </Grid>

                </section>
                <Divider className={classes.divider}/>
                <section className={classes.thirdSection}>
                    <Typography variant="h3" className={classes.sectionHeader}>Related objects</Typography>
                    <Grid container spacing={4} style={{paddingBottom: '1rem'}}>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            {attachedUniq && (
                                <Widget
                                    title="Attached Uniq"
                                    upperTitle
                                    disableWidgetMenu
                                    bodyClass={classes.fullHeightBody}
                                    className={classes.card}
                                >
                                    <>
                                        <Typography variant="h4" component="span" className={classes.title}>
                                            ID:
                                        </Typography>
                                        <Typography component="span">{attachedUniq.id}</Typography>
                                        <Typography variant="h4" component="span" className={classes.title}>
                                            Last update from sensor:
                                        </Typography>
                                        <Typography component="span">{attachedUniq.last_contact}</Typography>
                                        <Link to={`/uniqs/${attachedUniq.id}`}
                                              style={{marginTop: '2rem'}}>
                                            <Typography variant="h3" className={classes.link}>See uniq</Typography>
                                        </Link>
                                    </>
                                </Widget>
                            )}
                        </Grid>
                    </Grid>

                </section>
            </>}
        </>);
}
