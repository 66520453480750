import React from "react";
import {
    Grid,
} from "@material-ui/core";

import PageTitle from "../../components/PageTitle/PageTitle";


interface ProfilePageProps {

}

export default function ProfilePage(props: ProfilePageProps) {

    return (
        <>
            <PageTitle title="Profile"/>
            <Grid container spacing={4}>
                <Grid item lg={3} md={4} sm={6} xs={12}>

                </Grid>
            </Grid>
        </>
    );
}
