import React, {useState} from 'react'
import {API_BASE_URL} from '../config'
import {useAuthentication} from "./authentication";
import axios from 'axios'


export interface Infrastructure {
    id: string;
    name: string;
    lat: number;
    lng: number;
    image_direcory: string;

}

interface CollectionApiResponse {
    _embedded: {
        infrastructures: Infrastructure[]
    } | undefined
}


interface FavoriteResponse {
    favorite: boolean
}

interface APIContext {
    getAccessibleInfrastructures: () => Promise<Infrastructure[]>,
    getPinnedInfrastructures: () => Promise<Infrastructure[]>,
    getInfrastructureById: (id: string) => Promise<Infrastructure>,
    isFavorite: (id: string) => Promise<boolean>
    changeFavorite: (id: string, new_state: boolean) => Promise<boolean>
}

const InfrastructureContext = React.createContext<APIContext>({
    getAccessibleInfrastructures: () => {
        return new Promise((resolve, reject) => resolve([] as Infrastructure[]))
    },
    getPinnedInfrastructures: () => {
        return new Promise((resolve, reject) => resolve([] as Infrastructure[]))
    },
    getInfrastructureById: () => {
        return new Promise((resolve, reject) => resolve({} as Infrastructure))
    },
    isFavorite: (id: string) => {
        return new Promise((resolve, reject) => resolve(false))
    },
    changeFavorite: (id: string, new_state: boolean) => {
        return new Promise((resolve, reject) => resolve(false))
    }
})

const InfrastructureProvider = (props: any) => {
    const {tokens: {access_token}, logout} = useAuthentication();

    const getAccessibleInfrastructures = () => {

        const url = `${API_BASE_URL}/infrastructures/my`

        return axios.get(url, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }).then(result => {
            const response: CollectionApiResponse = result.data;
            if (response._embedded) {
                return response._embedded.infrastructures
            } else return []
        }).catch(reason => {
            logout();
        })

    }

    const getPinnedInfrastructures = () => {

        const url = `${API_BASE_URL}/infrastructures/my/favorite`

        return axios.get(url, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }).then(result => {
            const response: CollectionApiResponse = result.data;
            if (response._embedded) {
                return response._embedded.infrastructures
            } else return []
        }).catch(reason => {
            logout();
        })

    }

    //@TODO rework this to get by id endpoint
    const getInfrastructureById = (id: string) => {

        const url = `${API_BASE_URL}/infrastructures/my`

        return axios.get(url, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }).then(result => {
            const response: CollectionApiResponse = result.data;
            if (!response._embedded) {
                return undefined;
            }

            for (const infrastructure of response._embedded.infrastructures) {
                if (infrastructure.id === id) {
                    return infrastructure
                }
            }
        }).catch(reason => {
            logout();
        })

    }

    const isFavorite = (id: string) => {
        const url = `${API_BASE_URL}/infrastructures/my/favorite/${id}`

        return axios.get(url, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }).then(response => {
            const favorite: FavoriteResponse = response.data
            return favorite.favorite;
        }).catch(_ => {
            logout();
        })
    }

    const changeFavorite = (id: string, new_state: boolean) => {
        const url = `${API_BASE_URL}/infrastructures/my/favorite/${id}`

        const http_method_to_use = new_state ? ((url: string, header: any) => {
                return axios.put(url, undefined, header)
            })
            : ((url: string, header: any) => {
                return axios.delete(url, header)
            })

        return http_method_to_use(url, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }).then(_ => {
            return true;
        }).catch(_ => {
            logout();
        })
    }


    return (<InfrastructureContext.Provider
        value={{
            getAccessibleInfrastructures,
            getInfrastructureById,
            getPinnedInfrastructures,
            isFavorite,
            changeFavorite
        }} {...props}/>)
}
const useInfrastructures = () => React.useContext(InfrastructureContext)
export {InfrastructureProvider, useInfrastructures}
