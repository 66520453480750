import React from 'react'
import {API_BASE_URL} from '../config'
import {useAuthentication} from "./authentication";
import axios from 'axios'


export interface IncomingUniqStatus {
    id: string;
    data: string;
    datetime: Date;
}

export interface ParsedUniqStatus {
    id: string;
    data: {
        battery_voltage: number
    };
    send_at: Date;
}

interface CollectionUniqResponse {
    _embedded: {
        uniqs: [Uniq]
    }
}

interface StatusResponse extends IncomingUniqStatus {
    _links: {}
}

export interface Uniq {
    id: number,
    settings: string;
    key: string
    last_contact: Date;
}

export interface Timeslot {
    start_time_hour: number;
    start_time_minutes: number;
    stop_time_hour: number;
    stop_time_minutes: number;
    week_day: number;
    transmit_interval: number;
}

export interface UniqSettings {
    trigger_point: number
    snr_point: number,
    disable_snr_check: boolean,
    stay_awake: boolean,
    stay_connected: boolean,
    save_as_txt: boolean,
    timeslots: Timeslot[]
}

export interface ParsedUniq {
    id: number,
    settings: UniqSettings,
    key: string,
    last_contact: Date

}

interface ItemApiResponse extends Uniq {
    _links: object
}

interface APIContext {
    getStatusById: Function,
    getUniqByLocationId: (location_id: string) => Promise<ParsedUniq | undefined>,
    getUniqById: (location_id: string) => Promise<ParsedUniq | undefined>,
    setUniqById: Function
}

const SensorContext = React.createContext<APIContext>({
        getStatusById: () => [],
        getUniqByLocationId: () => new Promise((resolve) => resolve(undefined)),
        getUniqById: () => new Promise((resolve) => resolve(undefined)),
        setUniqById: () => []
    }
)

const UniqProvider = (props: any) => {
    const {tokens: {access_token}, logout} = useAuthentication()

    const getStatusById = (uniq_id: string) => {
        const url = `${API_BASE_URL}/uniqs/id/${uniq_id}/status/latest`

        return axios.get(url, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }).then(result => {
            const incoming_status: StatusResponse = result.data;
            if (!!incoming_status) {
                const parsed_data = JSON.parse(incoming_status.data);
                return {
                    ...incoming_status,
                    'data': parsed_data
                }
            }
        })
            .catch(error => {
                if (error.response.status === 404) {
                    return;
                }
                logout();
            })

    }

    const getUniqByLocationId = (location_id: string) => {
        const url = `${API_BASE_URL}/uniqs/uniq_location/${location_id}`

        return axios.get(url, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }).then(result => {
            const response: ItemApiResponse = result.data;
            const incoming_sensor = response;
            if (!!incoming_sensor.settings) {
                const parsed_data = JSON.parse(incoming_sensor.settings);
                return {
                    ...incoming_sensor,
                    'settings': parsed_data
                } as ParsedUniq
            }
            return {
                ...incoming_sensor,
                settings: {
                    trigger_point: 0,
                    snr_point: 0,
                    disable_snr_check: true,
                    stay_awake: false,
                    stay_connected: false,
                    save_as_txt: true,
                    timeslots: []
                } as UniqSettings
            } as ParsedUniq;
        })
            .then((parsedSensor: ParsedUniq) => {
                return {
                    ...parsedSensor,
                    settings: {
                        ...parsedSensor.settings,
                        timeslots: parsedSensor.settings.timeslots.filter(slot => slot.week_day !== 0)
                    }
                } as ParsedUniq
            })
            .catch(e => {
                if (e.response.status == 404) return;
                logout();
            })

    }

    const getUniqById = (uniq_id: string) => {
        const url = `${API_BASE_URL}/uniqs/id/${uniq_id}`

        return axios.get(url, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }).then(result => {
            const response: ItemApiResponse = result.data;
            const incoming_sensor = response;
            if (!!incoming_sensor.settings) {
                const parsed_data = JSON.parse(incoming_sensor.settings);
                return {
                    ...incoming_sensor,
                    'settings': parsed_data
                } as ParsedUniq
            }
            return {
                ...incoming_sensor,
                settings: {
                    trigger_point: 0,
                    snr_point: 0,
                    disable_snr_check: true,
                    stay_awake: false,
                    stay_connected: false,
                    save_as_txt: true,
                    timeslots: []
                } as UniqSettings
            } as ParsedUniq;
        })
            .then((parsedSensor: ParsedUniq) => {
                return {
                    ...parsedSensor,
                    settings: {
                        ...parsedSensor.settings,
                        timeslots: parsedSensor.settings.timeslots.filter(slot => slot.week_day !== 0)
                    }
                } as ParsedUniq
            })
            .catch(_ => {
                logout();
            })

    }

    const setUniqById = (sensor_id: string, sensor: ParsedUniq) => {
        const url = `${API_BASE_URL}/uniqs/id/${sensor_id}`
        const apiSensor = {
            ...sensor,
            settings: JSON.stringify(sensor.settings)
        }
        return axios.post(url, apiSensor,
            {
                headers: {
                    'Authorization':
                        `Bearer ${access_token}`
                }
            }
        ).then(_ => {
            return {
                success: true,
                message: "Sensor successfully saved."
            };
        })
            .catch(error => {
                return {
                    success: false,
                    message: error.toString()
                }
            })

    }


    return (<SensorContext.Provider
        value={{
            getStatusById,
            getUniqByLocationId,
            getUniqById,
            setUniqById
        }} {...props}/>)
}
const useUniqs = () => React.useContext(SensorContext)
export {UniqProvider, useUniqs}

