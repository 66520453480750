import React, {useState} from 'react'
import {API_BASE_URL} from '../config'
import {useAuthentication} from "./authentication";
import axios from "axios";
import traverson from "traverson";


export interface RawUniqMeasurement {
    id: string;
    location_id: string;
    loaded: boolean;
    ch1: number[];
    ch2: number[];
    ch3: number[];
    ch4: number[];
    measured_at: string;
}

export interface SimplifiedRawUniqMeasurement {
    id: string;
    location_id: string;
}

export interface AkzoNobel {
    id: string;
    output_1: number;
    output_2: number;
    output_3: number;
    output_4: number;
    output_5: number;
}


interface SimplifiedRawMeasurementCollectionApiResponse {
    _embedded: {
        raw_uniq_measurements: SimplifiedRawUniqMeasurement[]
    },
    _links: {
        next?: {
            href: string
        }
    }

}

interface APIContext {
    getSimplifiedMeasurementsByLocationId: (id: string) => Promise<SimplifiedRawUniqMeasurement[] | undefined>,
    downloadIdsInList: (ids: number[]) => void,
    getFullMeasurementById: (id: string) => Promise<RawUniqMeasurement | undefined>,
    getAkzoNobelById: (dataset_id: number, id: string) => Promise<AkzoNobel | undefined>,
    parseRawUniqMeasurementUsingSetting: (setting_id: string) => Promise<RawUniqMeasurement | undefined>
}

const RawUniqMeasurementContext = React.createContext<APIContext>({
    getSimplifiedMeasurementsByLocationId: (id: string) => {
        return new Promise<SimplifiedRawUniqMeasurement[] | undefined>((resolve) => resolve(undefined));
    },
    getFullMeasurementById: (id: string) => {
        return new Promise<RawUniqMeasurement | undefined>((resolve) => resolve(undefined));
    },
    getAkzoNobelById: (dataset_id: number, id: string) => {
        return new Promise<AkzoNobel | undefined>((resolve) => resolve(undefined));
    },
    parseRawUniqMeasurementUsingSetting: (id: string) => {
        return new Promise<RawUniqMeasurement | undefined>((resolve) => resolve(undefined));
    },
    downloadIdsInList: (ids: number[]) => {

    }
})

const RawUniqMeasurementProvider = (props: any) => {
    const {tokens: {access_token}, logout} = useAuthentication()

    const iterate: (next_url: string) => Promise<SimplifiedRawUniqMeasurement[] | undefined> = (next_url: string) => {
        let results: SimplifiedRawUniqMeasurement[] = []
        return axios.get(next_url, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }).then(async (result) => {
            const response: SimplifiedRawMeasurementCollectionApiResponse = result.data;
            if (!response._embedded) return results
            results = results.concat(response._embedded.raw_uniq_measurements)
            if (response._links.next === undefined) {
                return results
            } else {
                const layerDownResult = await iterate(response._links.next.href);
                if (layerDownResult === undefined) return undefined;
                return results.concat(layerDownResult)
            }
        }).catch(e => logout());
    }

    const getSimplifiedMeasurementsByLocationId = (location_id: string) => {
        const url = `${API_BASE_URL}/raw_uniq_measurements/uniq_location/${location_id}/simplified?size=500`
        return new Promise<SimplifiedRawUniqMeasurement[] | undefined>((resolve) => iterate(url).then(results => resolve(results)));
    }

    const downloadIdsInList = (ids_to_download: number[]) => {
        const url = `${API_BASE_URL}/raw_uniq_measurements/zip`

        return axios.post(url, {idsToDownload: ids_to_download}, {
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${access_token}`,
                'Content-Type': 'application/json',
                Accept: 'application/zip',
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'measurements.zip'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(reason => {
            logout();
        })

    }

    const getFullMeasurementById = (id: string) => {

        const url = `${API_BASE_URL}/raw_uniq_measurements/id/${id}`

        return axios.get(url, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }).then(result => {
            return result.data
        }).catch(reason => {
            logout();
        })

    }

    const getAkzoNobelById = (dataset_id: number, measurement_id: number) => {
        const url = `${API_BASE_URL}/akzonobels/dataset/${dataset_id}/raw_uniq_measurement/${measurement_id}`
        return new Promise<AkzoNobel | undefined>((resolve, reject) => {
            traverson.from(url).withRequestOptions({
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).json().getResource((error: any, result, traversal) => {
                if (error) {
                    reject();
                    return undefined;
                }
                const akzoNobel: AkzoNobel = result;
                resolve(akzoNobel)
            })
        });
    }


    return (<RawUniqMeasurementContext.Provider
        value={{
            getSimplifiedMeasurementsByLocationId,
            getFullMeasurementById,
            getAkzoNobelById,
            downloadIdsInList
        }} {...props}/>)
}
const useRawUniqMeasurements = () => React.useContext(RawUniqMeasurementContext)
export {RawUniqMeasurementProvider, useRawUniqMeasurements}
