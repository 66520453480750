import React, {useEffect, useState} from "react";
import {
    Button,
    CircularProgress, Divider, Grid, Typography,
} from "@material-ui/core";
import useStyles from './styles'
import {Dataset, useDatasets} from "../../context/datasets";
import {Measurement, useMeasurements} from "../../context/measurements";
import WaveForm from "../../components/Waveform";
import MUIDataTable from "mui-datatables";
import {Favorite, FavoriteBorder} from "@material-ui/icons";
import XYPlot from "../../components/XYPlot";

interface DatasetDetailPageProps {

    match: {
        params: {
            dataset_id: number
        }
    }

}

const measurementColumns = [
    {
        name: "id",
        label: "Id",
    },
    {
        name: "x",
        label: "X (cm)"
    },
    {
        name: "y",
        label: "Y (cm)"
    },
    {
        name: "snr",
        label: "SNR"
    },
    {
        name: "run_at",
        label: 'Run at'
    },
];

export default function DatasetDetailPage(props: DatasetDetailPageProps) {

    const [dataset, setDataset] = useState<Dataset | undefined>(undefined);
    const [measurements, setMeasurements] = useState<Measurement[] | undefined>(undefined)
    const [filteredMeasurements, setFilteredMeasurements] = useState<Measurement[] | undefined>(undefined)

    const {getDatasetById} = useDatasets()
    const {getMeasurementsByDataset} = useMeasurements()


    const classes = useStyles();

    const cleanMeasurements = (measurements: Measurement[] | undefined) => {
        if (!measurements) return;
        return measurements.map(m => {
            return {
                ...m,
                x: parseFloat(m.x.toFixed(2)),
                y: parseFloat(m.y.toFixed(2))
            }
        })
    }

    const refresh = () => {
        getDatasetById(props.match.params.dataset_id).then(dataset => {
            setDataset(dataset)
        })
        getMeasurementsByDataset(props.match.params.dataset_id).then(measurements => {
            setMeasurements(cleanMeasurements(measurements))
            setFilteredMeasurements(measurements);
        })
    }


    useEffect(() => {
        refresh();
    }, []);

    return (
        <>
            {!dataset && <CircularProgress size={26} className={classes.titleLoader}/>
            }
            {dataset && <>
                <Grid container className={classes.titleContainer}>
                    <Grid item justifyContent="center">
                        <Typography className={classes.typo} variant="h2">
                            {dataset.name}
                        </Typography>
                    </Grid>
                    <Grid style={{flexGrow: 1}}/>
                    <Grid item className={classes.actionContainer}>
                        <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() => refresh()}
                        >
                            Refresh
                        </Button>
                    </Grid>
                </Grid>
                <Divider className={classes.divider}/>
                <Typography variant="h3" className={classes.sectionHeader}>Processed measurements</Typography>
                <Grid container spacing={4}>
                    <Grid container item lg={7} md={4} sm={6} xs={12}>
                        <XYPlot data={filteredMeasurements || []}/>
                    </Grid>
                    <Grid item lg={5} md={4} sm={6} xs={12}>
                        <MUIDataTable columns={measurementColumns} data={measurements || []}
                                      title={"Processed measurements"} options={{
                            download: false,
                            print: false,
                            tableBodyHeight: '625px',
                            customToolbarSelect: (rows) => (<></>),
                            onTableChange: (action, state) => {
                                if (action !== 'filterChange') return;
                                const filteredData = state.displayData.reduce((prev, d) => {
                                    if (measurements && measurements[d.dataIndex] !== undefined) {
                                        return prev.concat([measurements[d.dataIndex]])
                                    } else {
                                        return prev
                                    }
                                }, [] as Measurement[])
                                setFilteredMeasurements(filteredData)
                            },
                            selectableRows: 'multiple',
                            onRowClick: (data, rowMeta: { dataIndex: number; rowIndex: number }) => {
                                if (!measurements) return
                            }

                        }}/>
                    </Grid>
                </Grid>


            </>}
        </>);
}
