import React, {useState} from 'react'
import {API_BASE_URL} from '../config'
import {useAuthentication} from "./authentication";
import axios from 'axios'
import {UniqLocation} from "./uniq_locations";


export interface Measurement {
    id: string;
    x: number;
    y: number;
    uncertainty: number;
    ch1: number[];
    ch2: number[];
    ch3: number[];
    ch4: number[];
    snr: number;
    created_at: string;
    sensor: UniqLocation;
}

interface CollectionApiResponse {
    _embedded: {
        processed_uniq_measurements: Measurement[]
    },
    _links: {
        next?: {
            href: string
        }
    }

}

interface APIContext {
    getMeasurementsByDataset: (dataset_id: number) => Promise<Measurement[] | undefined>;
}

const MeasurementContext = React.createContext<APIContext>({
    getMeasurementsByDataset: (dataset_id: number) => new Promise(resolve => resolve(undefined))
})

const MeasurementProvider = (props: any) => {
    const {tokens: {access_token}, logout} = useAuthentication()

    const iterate: (next_url: string) => Promise<Measurement[] | undefined> = (next_url: string) => {
        let results: Measurement[] = []
        return axios.get(next_url, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }).then(async (result) => {
            const response: CollectionApiResponse = result.data;
            if (!response._embedded) return results
            results = results.concat(response._embedded.processed_uniq_measurements)
            if (response._links.next === undefined) {
                return results
            } else {
                const layerDownResult = await iterate(response._links.next.href);
                if (layerDownResult === undefined) return undefined;
                return results.concat(layerDownResult)
            }
        }).catch(e => logout());
    }

    const getMeasurementsByDataset = (dataset_id: number) => {
        const url = `${API_BASE_URL}/processed_uniq_measurements/dataset/${dataset_id}?size=500`
        return new Promise<Measurement[] | undefined>((resolve) => iterate(url).then(results => resolve(results)));
    }


    return (<MeasurementContext.Provider value={{getMeasurementsByDataset}} {...props}/>)
}
const useMeasurements = () => React.useContext(MeasurementContext)
export {MeasurementProvider, useMeasurements}

