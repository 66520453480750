import React from "react";
import {
    Redirect,
    Route,
    Switch,
    withRouter
} from "react-router-dom";
import classnames from "classnames";


// styles
import useStyles from "./styles";

// components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

// pages
import Dashboard from "../../pages/dashboard/Dashboard";

// context
import {useSidebar} from "../../context/layout";
import InfrastructuresPage from "../../pages/infrastructures/InfrastructuresPage";
import InfrastructureDetailPage from "../../pages/infrastructure_detail/InfrastructureDetailPage";
import UniqLocationDetailPage from "../../pages/uniq_location_detail_page/UniqLocationDetailPage";
import ReportsPage from "../../pages/reports/ReportsPage";
import DatasetDetailPage from "../../pages/dataset_detail_page/DatasetDetailPage";
import SensorDetailPage from "../../pages/sensor_detail_page/SensorDetailPage";
import ProfilePage from "../../pages/profile/ProfilePage";

const Layout: React.FC = (props) => {
    const classes = useStyles();
    const layoutState = useSidebar();

    return (
        <div className={classes.root}>
            <>
                <Header/>
                <Sidebar/>
                <div
                    className={classnames(classes.content, {
                        [classes.contentShift]: layoutState.isSidebarOpened,
                    })}
                >
                    <div className={classes.fakeToolbar}/>
                    <Switch>
                        <Route exact path="/dashboard" component={Dashboard}/>
                        <Route exact path="/profile" component={ProfilePage}/>
                        <Route exact path="/infrastructures" component={InfrastructuresPage}/>
                        <Route exact path="/infrastructures/:infrastructure_id" component={InfrastructureDetailPage}/>
                        <Route exact path="/datasets/:dataset_id" component={DatasetDetailPage}/>
                        <Route exact path="/uniqs/:uniq_id" component={SensorDetailPage}/>
                        <Route exact path="/infrastructures/uniqs_by_location/:uniq_location_id"
                               component={UniqLocationDetailPage}/>
                        <Route exact path="/reports" component={ReportsPage}/>
                        <Route path="/" render={() => <Redirect to="/dashboard"/>}/>
                    </Switch>
                </div>
            </>
        </div>
    );
}

export default withRouter(Layout);
