import React, {useEffect, useState} from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from "moment";
import {UniqLocation} from "../../context/uniq_locations";


interface HistogramProps {
    data: any,
    location: UniqLocation | undefined
}

export default function HistogramPlot(props: HistogramProps) {

    const [dates, setDates] = useState([] as string[]);
    const [events, setEvents] = useState([] as number[])


    useEffect(() => {
        if (props.data === undefined || props.location === undefined) {
            setDates([])
            setEvents([])
            return
        }
        if (props.data.length === 0) {
            setDates([])
            setEvents([])
            return
        }
        const datesInHistogram = Object.keys(props.data);
        let moments = datesInHistogram.map(d => moment(d));
        const maxDate = moment.max(moments);
        const minDate = moment.max(moment.min(moments), moment(props.location.created_at));
        let allDates = [];
        let allEvents = []

        for (var m = moment(minDate); m.isBefore(maxDate); m.add(1, 'days')) {
            allDates.push(m.format('YYYY-MM-DD'));
            // @ts-ignore
            allEvents.push(props.data[m.format('YYYY-MM-DD')] || 0)
        }
        setDates(allDates);
        setEvents(allEvents)

    }, [props.data]);


    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={{
                chart: {
                    zoomType: "xy"
                },
                title: {
                    text: 'Amount of events per day'
                },
                xAxis: {
                    title: {
                        enabled: true,
                        text: 'Dates'
                    },
                    categories: dates
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Amount of events'
                    }
                },
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle'
                },
                series: [{
                    type: 'column',
                    data: events
                }

                ]
            }}/>
    )
}
