import React, {useEffect, useState} from 'react';
import {ParsedUniq, ParsedUniqStatus, UniqSettings, Timeslot, useUniqs} from "../../context/uniqs";
import {Grid, IconButton, Select, Switch, TextField} from "@material-ui/core";
import {Add, Refresh, RemoveCircle, Save} from "@material-ui/icons";
import {useSnackbar} from 'notistack';
import Flatpickr from "react-flatpickr";

import "flatpickr/dist/themes/material_blue.css";

interface UniqLocationDetailPageProps {

    match: {
        params: {
            uniq_id: string
        }
    }

}

const SensorDetailPage = (props: UniqLocationDetailPageProps) => {
    const {getStatusById, getUniqById, setUniqById} = useUniqs();
    const {enqueueSnackbar} = useSnackbar();


    const [edited, setEdited] = useState<boolean>(false);
    const [status, setStatus] = useState<ParsedUniqStatus | undefined>(undefined)
    const [initialSensor, setInitialSensor] = useState<ParsedUniq | undefined>(undefined)
    const [currentSettings, setCurrentSettings] = useState<UniqSettings | undefined>(undefined);

    const refresh = () => {

        getUniqById(props.match.params.uniq_id).then(
            (incoming_uniq: ParsedUniq | undefined) => {
                if (incoming_uniq === undefined) return
                setInitialSensor(incoming_uniq)
                setCurrentSettings(incoming_uniq.settings)
                getStatusById(incoming_uniq.id).then(
                    (incoming_status: ParsedUniqStatus) => {
                        setStatus(incoming_status)
                    }
                )
            });
    }


    useEffect(() => {
        refresh()
    }, [])

    const renderTimeslot = (currentSettings: UniqSettings, timeslot: Timeslot, index: number) => (


            <Grid container item key={index} xs={3} direction="column" style={{position: 'relative'}}>
                <Grid item>From <Flatpickr
                    value={`${timeslot.start_time_hour}:${timeslot.start_time_minutes}`}
                    options={{
                        dateFormat: "H:i",
                        noCalendar: true,
                        enableTime: true,
                        time_24hr: true
                    }}
                    onChange={dates => {
                        let newTimeslot = {
                            ...timeslot,
                            start_time_hour: dates[0].getHours(),
                            start_time_minutes: dates[0].getMinutes()
                        }
                        setCurrentSettings({
                            ...currentSettings,
                            timeslots: Object.assign([], currentSettings.timeslots, {[index]: newTimeslot})
                        })
                        setEdited(true)
                    }}/></Grid>
                <Grid item>To <Flatpickr
                    value={`${timeslot.stop_time_hour}:${timeslot.stop_time_minutes}`}
                    options={{
                        dateFormat: "H:i",
                        noCalendar: true,
                        enableTime: true,
                        time_24hr: true
                    }}
                    onChange={dates => {
                        let newTimeslot = {
                            ...timeslot,
                            stop_time_hour: dates[0].getHours(),
                            stop_time_minutes: dates[0].getMinutes()
                        }
                        setCurrentSettings({
                            ...currentSettings,
                            timeslots: Object.assign([], currentSettings.timeslots, {[index]: newTimeslot})
                        })
                        setEdited(true)
                    }}/></Grid>
                <Grid item>On<Select
                    style={{minWidth: 120}}
                    native
                    value={timeslot.week_day}
                    onChange={event => {
                        const newTimeslot = {
                            ...timeslot,
                            week_day: parseInt(event.target.value as string)
                        }

                        setCurrentSettings({
                            ...currentSettings,
                            timeslots: Object.assign([], currentSettings.timeslots, {[index]: newTimeslot})
                        })
                        setEdited(true)
                    }}
                >
                    <option value={1}>Monday</option>
                    <option value={2}>Tuesday</option>
                    <option value={3}>Wednesday</option>
                    <option value={4}>Thursday</option>
                    <option value={5}>Friday</option>
                    <option value={6}>Saturday</option>
                    <option value={7}>Sunday</option>

                </Select></Grid>
                <Grid item>
                    <div>Every <TextField type="number" style={{minWidth: 120}} onChange={event => {
                        const newTimeslot = {
                            ...timeslot,
                            transmit_interval: parseInt(event.target.value)
                        }
                        setCurrentSettings({
                            ...currentSettings,
                            timeslots: Object.assign([], currentSettings.timeslots, {[index]: newTimeslot})
                        })
                        setEdited(true)
                    }} value={timeslot.transmit_interval}/>
                        seconds
                    </div>
                </Grid>
                <Grid item>
                    <IconButton onClick={() => {
                        setCurrentSettings({
                            ...currentSettings,
                            timeslots: currentSettings.timeslots.filter((_: any, i: any) => i !== index)

                        })
                    }}><RemoveCircle/></IconButton>
                </Grid>
            </Grid>
        )
    ;

    const emptyTimeslot = {
        start_time_hour: 0,
        start_time_minutes: 0,
        stop_time_hour: 0,
        stop_time_minutes: 0,
        week_day: 1,
        transmit_interval: 1000
    }

    return (
        <Grid direction="row" container className="pt4">
            <Grid item xs={3}>
                <div>
                    <div className="b">Status</div>
                    <div>{!!status && <>Voltage: {((status.data.battery_voltage * 2) / 4095 * 3.3).toFixed(2)}v</>}</div>
                    <div>{!!status && <>Latest update: {status.send_at}</>}</div>

                    {!status && <div>No status available</div>}
                </div>
                <div>
                    {!!currentSettings && <div className="mt2">
                        <div className="b">Settings</div>

                        <div>Trigger point: <TextField type="number" onChange={event => {
                            setCurrentSettings({...currentSettings, trigger_point: parseInt(event.target.value)})
                            setEdited(true)
                        }} value={currentSettings.trigger_point}/></div>

                        <div>SNR check enabled?: <Switch value={!currentSettings.disable_snr_check}
                                                         onChange={(event, value) => {
                                                             setCurrentSettings({
                                                                 ...currentSettings,
                                                                 disable_snr_check: !value
                                                             })
                                                             setEdited(true)
                                                         }}/>
                        </div>

                        <div>
                            SNR point:
                            <TextField disabled={currentSettings.disable_snr_check} type="number" onChange={event => {
                                setCurrentSettings({...currentSettings, snr_point: parseInt(event.target.value)})
                                setEdited(true)
                            }} value={currentSettings.snr_point}/>
                        </div>
                        <div>Stay awake enabled?: <Switch value={currentSettings.stay_awake}
                                                          onChange={(event, value) => {
                                                              setCurrentSettings({
                                                                  ...currentSettings,
                                                                  stay_awake: value
                                                              })
                                                              setEdited(true)
                                                          }}/>
                        </div>
                        <div>Stay connected enabled?: <Switch value={currentSettings.stay_connected}
                                                              onChange={(event, value) => {
                                                                  setCurrentSettings({
                                                                      ...currentSettings,
                                                                      stay_connected: value
                                                                  })
                                                                  setEdited(true)
                                                              }}/></div>
                        <div>Save as text enabled?: <Switch value={currentSettings.save_as_txt}
                                                            onChange={(event, value) => {
                                                                setCurrentSettings({
                                                                    ...currentSettings,
                                                                    save_as_txt: value
                                                                })
                                                                setEdited(true)
                                                            }}/></div>
                    </div>
                    }


                </div>
            </Grid>
            <Grid container item xs={9}>
                <Grid container item xs={12} direction="row">
                    {!!currentSettings && <>
                        <Grid item xs={6} className="b"> Timeslots ({currentSettings.timeslots.length} / 25)</Grid>
                        <Grid item container direction="row" xs={6} justify="flex-end">
                            <Grid item xs={9}/>
                            <Grid item xs={1}>
                                <IconButton aria-label="add" color="primary" onClick={() => {
                                    if (!currentSettings || !currentSettings.timeslots) return;
                                    if (currentSettings.timeslots.length >= 25) return;
                                    setCurrentSettings({
                                        ...currentSettings,
                                        "timeslots": [...currentSettings.timeslots, emptyTimeslot]
                                    })
                                }}>
                                    <Add/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={1}>
                                {initialSensor && (
                                    <IconButton aria-label="save" color="primary"
                                                disabled={!edited}
                                                onClick={() => {
                                                    setUniqById(initialSensor.id, {
                                                        ...initialSensor,
                                                        settings: currentSettings
                                                    }).then((result: { success: boolean, message: string }) => {
                                                        if (result.success) {
                                                            enqueueSnackbar(result.message, {variant: 'success'})
                                                        } else {
                                                            enqueueSnackbar(result.message, {variant: 'error'})
                                                        }

                                                    })
                                                }}>
                                        <Save/>
                                    </IconButton>)}
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton aria-label="refresh" color="primary" onClick={() => {
                                    refresh()
                                }}>
                                    <Refresh/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </>}
                </Grid>
                <Grid item container xs={11} direction="row" spacing={3}>
                    {!!currentSettings && currentSettings.timeslots.map((item: Timeslot, i: number) => renderTimeslot(currentSettings, item, i))}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SensorDetailPage;