import React, {useState} from 'react'
import {API_BASE_URL} from '../config'
import {useAuthentication} from "./authentication";
import axios from 'axios'
import {UniqLocation} from "./uniq_locations";


export interface Dataset {
    id: number
    name: string
    start_moment: Date
    stop_moment: Date
}

interface DatasetCollectionResponse {
    _embedded: {
        datasets: [Dataset]
    }
}

interface APIContext {
    getDatasetsByUniqLocation: (location_id: string) => Promise<Dataset[] | undefined>
    getDatasetById: (id: number) => Promise<Dataset | undefined>
}

const DatasetContext = React.createContext<APIContext>({
    getDatasetsByUniqLocation: (a: string) => new Promise<Dataset[] | undefined>(resolve => {
        resolve(undefined)
    }),
    getDatasetById: (a: number) => new Promise<Dataset | undefined>(resolve => {
        resolve(undefined)
    })
})

const DatasetProvider = (props: any) => {
    const {tokens: {access_token}, logout} = useAuthentication()

    const getDatasetsByUniqLocation = (location_id: string,) => {

        const url = `${API_BASE_URL}/datasets/uniq_location/${location_id}`

        return axios.get(url, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }).then(result => {
            const response: DatasetCollectionResponse = result.data;
            return response._embedded.datasets
        })
            .catch(reason => {
                logout();
            })
    }

    const getDatasetById = (id: number,) => {

        const url = `${API_BASE_URL}/datasets/id/${id}`

        return axios.get(url, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }).then(result => {
            const response: Dataset = result.data;
            return response
        })
            .catch(reason => {
                logout();
            })
    }


    return (<DatasetContext.Provider value={{getDatasetsByUniqLocation, getDatasetById}} {...props}/>)
}
const useDatasets = () => React.useContext(DatasetContext)
export {DatasetProvider, useDatasets}
