import React, {useState, useEffect} from "react";
import {Drawer, IconButton, List, Theme} from "@material-ui/core";
import {
    Home as HomeIcon,
    NotificationsNone as NotificationsIcon,
    TableChartOutlined as DatasetIcon,
    ArrowBack as ArrowBackIcon,
    DeviceHubOutlined as SensorIcon,
    PinDropOutlined as InfrastructureIcon,
    AssessmentOutlined as ReportIcon
} from "@material-ui/icons";
import {useTheme} from "@material-ui/styles";
import {withRouter} from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

// context
import {
    useSidebar,
    toggleSidebar,
} from "../../context/layout";

const structure = [
    {id: 0, label: "Dashboard", link: "/dashboard", icon: <HomeIcon/>},
    {
        id: 1,
        label: "Sensors",
        link: "/sensors",
        icon: <SensorIcon/>,
    },
    {id: 2, label: "Infrastructures", link: "/infrastructures", icon: <InfrastructureIcon/>},
    {
        id: 4,
        label: "Reports",
        link: "/reports",
        icon: <ReportIcon/>
    },
    {id: 5, type: "divider"},
    {id: 6, type: "title", label: "ADVANCED"},
    {id: 7, label: "Datasets", link: "/datasets", icon: <DatasetIcon/>},

];

function Sidebar() {

    const classes = useStyles();
    const theme: Theme = useTheme();

    // global
    const {isSidebarOpened, dispatch} = useSidebar()

    // local
    const [isPermanent, setPermanent] = useState(true);

    useEffect(function () {
        window.addEventListener("resize", handleWindowWidthChange);
        handleWindowWidthChange();
        return function cleanup() {
            window.removeEventListener("resize", handleWindowWidthChange);
        };
    });

    return (
        <Drawer
            variant={isPermanent ? "permanent" : "temporary"}
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: isSidebarOpened,
                [classes.drawerClose]: !isSidebarOpened,
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: isSidebarOpened,
                    [classes.drawerClose]: !isSidebarOpened,
                }),
            }}
            open={isSidebarOpened}
        >
            <div className={classes.toolbar}/>
            <div className={classes.mobileBackButton}>
                <IconButton onClick={() => toggleSidebar(dispatch)}>
                    <ArrowBackIcon
                        classes={{
                            root: classNames(classes.headerIcon, classes.headerIconCollapse),
                        }}
                    />
                </IconButton>
            </div>
            <List className={classes.sidebarList}>
                {structure.map(link => (
                    <SidebarLink
                        key={link.id}
                        isSidebarOpened={isSidebarOpened}
                        {...link}
                    />
                ))}
            </List>
        </Drawer>
    );

    // ##################################################################
    function handleWindowWidthChange() {
        const windowWidth = window.innerWidth;

        const breakpointWidth = theme.breakpoints.values.md;
        const isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && isPermanent) {
            setPermanent(false);
        } else if (!isSmallScreen && !isPermanent) {
            setPermanent(true);
        }
    }
}

export default withRouter(Sidebar);
