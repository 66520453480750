import React from "react";
import ReactDOM from "react-dom";
import {ThemeProvider} from "@material-ui/styles";
import {CssBaseline} from "@material-ui/core";

import theme from "./themes"
import {App} from "./App";
import ContextProvider from "./context";

import * as Highcharts from "highcharts";
import AnnotationsFactory from "highcharts/modules/annotations";
import HC_more from 'highcharts/highcharts-more';



HC_more(Highcharts);
AnnotationsFactory(Highcharts);


ReactDOM.render(
    <ContextProvider>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <App/>
        </ThemeProvider>
    </ContextProvider>,
    document.getElementById("root"),
)
;

