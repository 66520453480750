import * as React from 'react';
import {Marker} from 'react-map-gl';
import {Infrastructure} from "../../context/infrastructures";


const SIZE = 30;

interface PinProps {
    infrastructures: Infrastructure[],
    onClick: (infrastructure: Infrastructure) => void
}

function Pins({infrastructures, onClick}: PinProps) {
    return (<>
        {infrastructures.map((infrastructure: Infrastructure, index: number) =>
            (
                <Marker key={`marker-${index}`} longitude={infrastructure.lng} latitude={infrastructure.lat}>
                    <svg
                        height={SIZE}
                        viewBox="0 0 365 560"
                        style={{
                            cursor: 'pointer',
                            fill: '#d00',
                            stroke: 'none',
                            transform: `translate(${-SIZE / 2}px,${-SIZE}px)`
                        }}
                        onClick={() => onClick(infrastructure)}
                    >
                        <path xmlns="http://www.w3.org/2000/svg" fill="#00AEEF"
                              d="M182.9,551.7c0,0.1,0.2,0.3,0.2,0.3S358.3,283,358.3,194.6c0-130.1-88.8-186.7-175.4-186.9   C96.3,7.9,7.5,64.5,7.5,194.6c0,88.4,175.3,357.4,175.3,357.4S182.9,551.7,182.9,551.7z M122.2,187.2c0-33.6,27.2-60.8,60.8-60.8   c33.6,0,60.8,27.2,60.8,60.8S216.5,248,182.9,248C149.4,248,122.2,220.8,122.2,187.2z"/>
                    </svg>
                </Marker>
            ))}
    </>);
}

export default Pins;
