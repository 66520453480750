import React from "react";

import useStyles from "./styles";
import {Typography} from "@material-ui/core";

interface PageTitleProps {
    button?: JSX.Element
    favorite?: JSX.Element
    title: string
}

export default function PageTitle(props: PageTitleProps) {
    const classes = useStyles();

    return (
        <div className={classes.pageTitleContainer}>
            <div>
                <Typography className={classes.typo} variant="h2">
                    {props.title}
                </Typography>
            </div>
            <div className={classes.actionContainer}>
                {props.favorite && props.favorite}
                {props.button && props.button}
            </div>
        </div>
    );
}
