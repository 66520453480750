import React, {useEffect, useState} from "react";
import {
    Breadcrumbs,
    Button, CircularProgress, Divider,
    Grid, Typography, Fab, Box
} from "@material-ui/core";
import {Link, useHistory} from 'react-router-dom';
import {Infrastructure, useInfrastructures} from "../../context/infrastructures";
import useStyles from './styles'
import PageTitle from "../../components/PageTitle/PageTitle";
import {UniqLocation, useUniqLocations} from "../../context/uniq_locations";
import MUIDataTable from "mui-datatables";
import {AddIcon} from "@material-ui/data-grid";
import {Favorite, FavoriteBorder} from '@material-ui/icons'
import {SHMNextTheme} from "../../themes";
import {useTheme} from "@material-ui/styles";

interface InfrastructureDetailPageProps {

    match: {
        params: {
            infrastructure_id: string
        }
    }

}

const columns = [
    {
        name: "name",
        label: "Name",
        options: {
            filter: false
        }
    },
    {
        name: "id",
        label: "ID",
        options: {
            filter: false
        }
    },
    {
        name: "type",
        label: "Type",
    }
];

export default function InfrastructureDetailPage(props: InfrastructureDetailPageProps) {
    const {getInfrastructureById, isFavorite, changeFavorite} = useInfrastructures();
    const {getLocationsByInfrastructureId} = useUniqLocations();
    const [infrastructure, setInfrastructure] = useState<Infrastructure | undefined>(undefined)
    const [locations, setLocations] = useState<UniqLocation[] | undefined>(undefined)
    const [localPinned, setLocalPinned] = useState<boolean | undefined>(false)


    const classes = useStyles();


    const refresh = () => {
        getInfrastructureById(props.match.params.infrastructure_id).then(((infrastructures: Infrastructure) => {
                setInfrastructure(infrastructures)
            })
        )
        getLocationsByInfrastructureId(props.match.params.infrastructure_id).then(((locations: UniqLocation[] | undefined) => {
                setLocations(locations)
            })
        )
        isFavorite(props.match.params.infrastructure_id).then((fav: boolean) => {
            setLocalPinned(fav)
        })
    }

    useEffect(() => {
        refresh();
    }, []);

    const updatePin = (status: boolean) => {
        changeFavorite(props.match.params.infrastructure_id, status).then(_ => setLocalPinned(status))
    }

    const history = useHistory();
    const theme: SHMNextTheme = useTheme();

    return (
        <>
            {!infrastructure && <CircularProgress size={26} className={classes.titleLoader}/>
            }
            {infrastructure && <>
                <div>
                    <PageTitle title={infrastructure.name}
                               favorite={<span style={{color: theme.palette.secondary.main}}>
                                   {!localPinned &&
                                   <FavoriteBorder style={{cursor: 'pointer', marginRight: '1rem'}}
                                                   onClick={() => updatePin(true)}/>}
                                   {localPinned &&
                                   <Favorite style={{cursor: 'pointer', marginRight: '1rem'}}
                                             onClick={() => updatePin(false)}/>}
                               </span>}

                    />
                </div>
                <section>
                    <Grid container spacing={4} className={classes.fullHeight}>
                        <Grid item lg={5} md={4} sm={6} xs={12}>
                            <div>
                                <img alt="location image" src={`/images/${infrastructure.id}/large.jpg`} style={{
                                    backgroundColor: 'lightgray',
                                    borderRadius: '2px',
                                    marginBottom: '1rem',
                                    display: 'block',
                                    maxWidth: '500px'
                                }} width="100%"
                                     height="auto"/>
                                <Typography variant="h4" component="span" className={classes.title}>
                                    Description:
                                </Typography>
                                <Typography component="span">N/A</Typography>
                            </div>
                            <Divider className={classes.divider}/>
                        </Grid>
                        <Grid item lg={7} md={4} sm={6} xs={12}>
                            <MUIDataTable columns={columns} data={locations || []}
                                          title={"Uniq locations"} options={{
                                download: false,
                                print: false,
                                setTableProps: () => ({className: "tableHeight"}),
                                customToolbarSelect: (rows) => (<></>),
                                selectableRows: 'none',
                                onRowClick: (data, rowMeta: { dataIndex: number; rowIndex: number }) => {
                                    if (!locations) return;
                                    history.push(`/infrastructures/uniqs_by_location/${locations[rowMeta.dataIndex].id}`)
                                }

                            }}/>
                            <div className={classes.actionRow}>
                                <Fab color="primary" aria-label="add">
                                    <AddIcon/>
                                </Fab>
                            </div>
                        </Grid>
                    </Grid>
                </section>
            </>
            }

        </>
    );
}
