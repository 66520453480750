import React from "react";
import {AuthenticationProvider} from "./authentication";
import {LayoutProvider} from "./layout";
import {InfrastructureProvider} from "./infrastructures";
import {UniqLocationProvider} from "./uniq_locations";
import {RawUniqMeasurementProvider} from "./raw_uniq_measurements";
import {DatasetProvider} from "./datasets";
import {MeasurementProvider} from "./measurements";
import {UniqProvider} from "./uniqs";
import {SnackbarProvider} from "notistack";

const ContextProvider: React.FC = ({children}) => (
    <AuthenticationProvider>
        <LayoutProvider>
            <InfrastructureProvider>
                <UniqLocationProvider>
                    <RawUniqMeasurementProvider>
                        <DatasetProvider>
                            <MeasurementProvider>
                                <UniqProvider>
                                    <SnackbarProvider>
                                        {children}
                                    </SnackbarProvider>
                                </UniqProvider>
                            </MeasurementProvider>
                        </DatasetProvider>
                    </RawUniqMeasurementProvider>
                </UniqLocationProvider>
            </InfrastructureProvider>
        </LayoutProvider>
    </AuthenticationProvider>
)

export default ContextProvider
