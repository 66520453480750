import React from 'react';
import {
    CartesianGrid,
    Label,
    Line,
    LineChart,
    ResponsiveContainer,
    YAxis
} from "recharts";

interface WaveFormProps {
    width: number | string;
    height: number | string;
    title: string;
    data: number[];
}

const WaveForm: React.FC<WaveFormProps> = ({data, width, height, title}: WaveFormProps) => {

    return (
        <ResponsiveContainer width={width} height={height}>
            <LineChart
                data={data.map((n, index) => {
                    return {index: index, point: n}
                })}
                margin={{
                    top: 0, left: 30, right: 30, bottom: 0
                }}
            >
                <YAxis tick={false} domain={[0, 2 ** 16]}>
                    <Label angle={-90} value={title} position='insideLeft' style={{textAnchor: 'middle'}}/>
                </YAxis>
                <CartesianGrid stroke="#aaa" strokeDasharray="1 1"/>
                <Line type="monotone" dataKey="point" stroke="#000000" dot={false}/>
            </LineChart>
        </ResponsiveContainer>

    );
}

export default WaveForm