import React, {useState} from "react";
import {
    AppBar,
    Toolbar,
    IconButton,
    Menu,
    MenuItem, Typography
} from "@material-ui/core";
import {
    Menu as MenuIcon,
    Person as AccountIcon,
    ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";


import useStyles from "./styles";
import {Badge} from "../Wrappers/Wrappers";

// context
import {
    useSidebar,
    toggleSidebar
} from "../../context/layout";
import {useAuthentication} from "../../context/authentication";
import {Link} from 'react-router-dom'

export default function Header(props: any) {
    const classes = useStyles();
    const {logout, tokens} = useAuthentication()

    const {dispatch: sidebarDispatch, isSidebarOpened} = useSidebar()

    const [profileMenu, setProfileMenu] = useState<Element | undefined>(undefined);

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <IconButton
                    color="inherit"
                    onClick={() => toggleSidebar(sidebarDispatch)}
                    className={classNames(
                        classes.headerMenuButtonSandwich,
                        classes.headerMenuButtonCollapse,
                    )}
                >
                    {isSidebarOpened ? (
                        <ArrowBackIcon
                            classes={{
                                root: classNames(
                                    classes.headerIcon,
                                    classes.headerIconCollapse,
                                ),
                            }}
                        />
                    ) : (
                        <MenuIcon
                            classes={{
                                root: classNames(
                                    classes.headerIcon,
                                    classes.headerIconCollapse,
                                ),
                            }}
                        />
                    )}
                </IconButton>
                <Typography variant="h6" component="h6" className={classes.logotype}>
                    SHMNext
                </Typography>
                <div className={classes.grow}/>
                <IconButton
                    aria-haspopup="true"
                    color="inherit"
                    className={classes.headerMenuButton}
                    aria-controls="profile-menu"
                    onClick={e => setProfileMenu(e.currentTarget)}
                >
                    <AccountIcon classes={{root: classes.headerIcon}}/>
                </IconButton>
                <Menu
                    id="profile-menu"
                    open={Boolean(profileMenu)}
                    anchorEl={profileMenu}
                    onClose={() => setProfileMenu(undefined)}
                    className={classes.headerMenu}
                    classes={{paper: classes.profileMenu}}
                    disableAutoFocusItem
                >
                    <div className={classes.profileMenuUser}>

                        <Typography variant="h4" component="div">
                            Account

                        </Typography>
                    </div>
                    <MenuItem
                        className={classNames(
                            classes.headerMenuItem,
                        )}
                    >
                        <AccountIcon className={classes.profileMenuIcon}/> <Typography to={'/profile'}
                                                                                       style={{textDecoration: 'none'}}
                                                                                       component={Link}>{tokens.email}</Typography>
                    </MenuItem>
                    <div className={classes.profileMenuUser} onClick={() => {
                        logout();
                    }}>
                        <Typography
                            className={classes.profileMenuLink}
                            color="primary"
                        >
                            Sign Out
                        </Typography>
                    </div>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}
