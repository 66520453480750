import React, {useState} from "react";
import {
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@material-ui/core";
import {Inbox as InboxIcon} from "@material-ui/icons";
import {Link, useHistory, useLocation} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Dot from "../Dot";

interface SidebarLinkProps {
    link?: string,
    icon?: JSX.Element,
    label?: string,
    children?: { label?: string, link?: string }[]
    nested?: boolean,
    isSidebarOpened: boolean,
    type?: string
}

export default function SidebarLink({
                                        link,
                                        icon,
                                        label,
                                        children,
                                        isSidebarOpened,
                                        nested,
                                        type,
                                    }: SidebarLinkProps) {
    const classes = useStyles();
    const location = useLocation()
    const history = useHistory()

    // local
    const [isOpen, setIsOpen] = useState(false);
    const isLinkActive =
        link &&
        (location.pathname === link || location.pathname.indexOf(link) !== -1);

    if (type === "title")
        return (
            <Typography
                className={classnames(classes.linkText, classes.sectionTitle, {
                    [classes.linkTextHidden]: !isSidebarOpened,
                })}
            >
                {label}
            </Typography>
        );

    if (type === "divider") return <Divider className={classes.divider}/>;
    if (link && link.includes('http')) {
        return (
            <ListItem
                button
                className={classes.link}
                classes={{
                    root: classnames(classes.link, {
                        [classes.linkActive]: isLinkActive && !nested,
                        [classes.linkNested]: nested,
                    }),
                }}
                disableRipple
            >
                <a className={classes.externalLink} href={link}>
                    <ListItemIcon
                        className={classnames(classes.linkIcon, {
                            [classes.linkIconActive]: isLinkActive,
                        })}
                    >
                        {nested ? <Dot size="small" color={(isLinkActive && "primary") || undefined}/> : icon}
                    </ListItemIcon>
                    <ListItemText
                        classes={{
                            primary: classnames(classes.linkText, {
                                [classes.linkTextActive]: isLinkActive,
                                [classes.linkTextHidden]: !isSidebarOpened,
                            }),
                        }}
                        primary={label}
                    />
                </a>
            </ListItem>
        )
    }
    return (
        <>
            <ListItem
                button
                className={classes.link}
                onClick={() => history.push(link || "")}
            >
                <ListItemIcon
                    className={classnames(classes.linkIcon, {
                        [classes.linkIconActive]: isLinkActive,
                    })}
                >
                    {icon ? icon : <InboxIcon/>}
                </ListItemIcon>
                <ListItemText
                    classes={{
                        primary: classnames(classes.linkText, {
                            [classes.linkTextActive]: isLinkActive,
                            [classes.linkTextHidden]: !isSidebarOpened,
                        }),
                    }}
                    primary={label}
                />
            </ListItem>
        </>
    );
}
