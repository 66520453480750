import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {withStyles} from "@material-ui/core/styles";

const defaultToolbarStyles = {
    iconButton: {},
};

interface SelectToolbarProps {
    classes: any,
    handleClick: () => any
}

const SelectToolbar = (props: SelectToolbarProps) => {

    const {classes} = props;

    return (
        <React.Fragment>
            <Tooltip title={"Select all on page"}>
                <IconButton className={classes.iconButton} onClick={props.handleClick}>
                    <CheckBoxIcon className={classes.deleteIcon}/>
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
}


export default withStyles(defaultToolbarStyles, {name: "SelectToolbar"})(SelectToolbar);