import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {Measurement} from "../../context/measurements";

interface XYPlotProps {
    data: Measurement[]
}

const sensorWidthInCm = 0.34;
const sensorHeightInCm = 0.20;

const XYPlot = (props: XYPlotProps) => {

    return (
        <HighchartsReact
            containerProps={{style: {height: "100%", width: "100%"}}}
            highcharts={Highcharts}
            options={{
                chart: {
                    zoomType: "xy",
                    type: "scatter"
                },
                title: {
                    text: 'Measurements'
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    max: 2,
                    min: -2,
                    gridLineWidth: 1,
                    plotLines: [{
                        value: 0,
                        width: 3
                    }],
                    title: {
                        enabled: true,
                        text: 'X-location (m)'
                    },
                    startOnTick: true,
                    endOnTick: true,
                    showLastLabel: true,

                },
                yAxis: {
                    max: 2,
                    min: -2,
                    title: {
                        text: 'Y-location (m)'
                    },
                    plotLines: [{
                        value: 0,
                        width: 3
                    }],
                },
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle'
                },
                plotOptions: {
                    scatter: {
                        marker: {
                            radius: 3,
                            states: {
                                hover: {
                                    enabled: true,
                                    lineColor: 'rgb(100,100,100)'
                                }
                            }
                        },
                        states: {
                            hover: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        tooltip: {
                            headerFormat: '<b>Location of measurement</b><br>',
                            pointFormat: '{point.x} cm, {point.y} cm'
                        }
                    }
                },
                series: {
                    name: `Measurements`,
                    data: props.data.map(p => [p.x, p.y])
                },
                annotations: [{
                    draggable: '',
                    shapes:
                        [{
                            type: 'path',
                            stroke: 'black',
                            fill: 'rgba(255,0,0,0)',
                            strokeWidth: 1,
                            points: [
                                {x: -(sensorWidthInCm / 2), y: -(sensorHeightInCm / 2), xAxis: 0, yAxis: 0},
                                {x: -(sensorWidthInCm / 2), y: (sensorHeightInCm / 2), xAxis: 0, yAxis: 0},
                                {x: (sensorWidthInCm / 2), y: (sensorHeightInCm / 2), xAxis: 0, yAxis: 0},
                                {x: (sensorWidthInCm / 2), y: -(sensorHeightInCm / 2), xAxis: 0, yAxis: 0},
                                {x: -(sensorWidthInCm / 2), y: -(sensorHeightInCm / 2), xAxis: 0, yAxis: 0},

                            ],
                        }]
                }]
            }}/>
    );
}

export default XYPlot
